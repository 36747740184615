/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useCallback, useEffect, useState } from "react";
import SmartForm from "src/container/smart-form.component";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { addStockistCompany, editStockistCompany, queryStockist } from "src/services/stockist-api";
import { getCounterTypes } from "src/services/common-api";
// import { getDistrictDetails } from "src/services/region-api";
import { emailStatusHandler, mobileStatusHandler, stockistAliasCodeHandler, stockistCodeHandler } from "src/utils/utils";
import * as yup from "yup";
import StockistCluster from "./stockistCluster-form.component";
import { toast } from "src/hooks/useToast";

interface StockistLinkFormProps {
  onAddFormSubmit?: (formData: any) => void;
  onCancelClick?: () => void;
  onSuccess?: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const StockistLinkForm: React.FC<StockistLinkFormProps> = ({ onCancelClick, onSuccess, loading, setLoading }) => {
  // Define the schema object for the form validation
  // const [schemaObj, setSchemaObj] = useState<any>({});
  const [companydata, setcompanydata] = useState<any>({});
  const [moduleList, setModuleList] = useState([]);
  // const [districtList, setDistrictList] = useState([]);
  const [stockistApplicableSections, setStockistApplicableSections] = useState<Record<string, boolean>>({ Medvol: false, POB: false, PUC: false });
  const companyList = useSelector((state: RootState) => state.company.companyDetails);
  const [selectedModules, setSelectedModules] = useState<any>([]);

  const {
    addEditFormData,
    selectedStockistData,
    stockistLinkEditMode,
    mainFormEditMode,
    independentCompanyAdd,
    clusterFormSectionCounter,
    isAddStockist,
  } = useSelector((state: RootState) => state.stockist);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define the schema object for the form validation
  const [schemaObj, setSchemaObj] = useState<any>({
    company: yup.object().required(),
    operator_name: yup.string().notRequired(),
    operator_mobile: yup
      .number()
      .positive()
      .notRequired()
      .transform((value) => {
        return isNaN(value) ? undefined : value;
      })
      .test("len", "Mobile number should 10 characters", (val: any) => {
        if (!val || String(val).length === 10) return true;
        else return false;
      }),
    operator_email: yup
      .string()
      .required()
      .email("Invalid email format")
      .test("managerEmailId", "Invalid email Id", (val, context) => {
        if (typeof val === "string") emailStatusHandler(val, context);
        return true;
      }),
    plantCode: yup.string().required(),
    compStockistCode: yup
      .string()
      .required()
      .notOneOf([yup.ref("compStockistAliasCode"), null], "Stockist Alias code & Stockist Code should be different"), //
    compStockistAliasCode: yup.string().required(),
    stockist_type: yup.object().required("Stockist form is required"),
    elnv_exempt: yup.object().required("E-inv exempt is required"),
    reference_number: yup.string().required(),
    module: yup.array().required().min(1, "Stockist Applicable For is a required field"),
  });

  // Loop through the number of sections and add the required fields to the schema object
  useEffect(() => {
    const createValidationForClusterFields = (_fieldName: string, message: string) => {
      return yup
        .object()
        .nullable()
        .test("module", message, function (value) {
          const { module } = this.parent;
          const isMedvolSelected = module?.some((item: any) => item?.module_desc === "Medvol");
          return !isMedvolSelected || !!value;
        });
    };

    const updatedSchemaObj: any = {
      company: yup.object().required(),
      operator_name: yup.string().notRequired(),
      operator_mobile: yup
        .number()
        .positive()
        .notRequired()
        .nullable()
        .transform((value) => {
          return isNaN(value) ? undefined : value;
        })

        .test("len", "Mobile number should 10 characters", (val: any) => {
          if (!val || String(val).length === 10) return true;
          else return false;
        })
        .test("mobile-validation", "Invalid mobile number", (val, context) => {
          if (!val) return true;
          return mobileStatusHandler(val, context, mainFormEditMode ? `user_type=stockist&user_code=${selectedStockistData?.medvol_code}` : "");
        }),

      operator_email: yup
        .string()
        .required()
        .email()
        .test("managerEmailId", "Invalid email Id", (val, context) => {
          if (typeof val === "string") emailStatusHandler(val, context);
          return true;
        }),
      plantCode: yup.string().required(),
      compStockistCode: yup
        .string()
        .required()
        .test("stockist-validation", "Invalid Stockist code", function (val, context) {
          if (!val) return true;
          const { company } = this.parent;
          if (!company?.code) return context.createError({ message: "Please select company first" });
          return stockistCodeHandler(val, company?.code || "", context, addEditFormData?.id ? `id=${addEditFormData?.id}` : "");
        }),
      // .notOneOf([yup.ref("compStockistAliasCode"), null], "Stockist Alias code & Stockist Code should be different"), //
      compStockistAliasCode: yup
        .string()
        .required()
        .test("stockist-validation", "Invalid stockist alias code", function (val, context) {
          if (!val) return true;
          const { company } = this.parent;
          if (!company?.code) return context.createError({ message: "Please select company first" });
          return stockistAliasCodeHandler(val, company?.code || "", context, addEditFormData?.id ? `id=${addEditFormData?.id}` : "");
        }),
      elnv_exempt: yup.object().required("E-inv exempt is required"),
      reference_number: yup.string().required(),
      module: yup.array().required().min(1, "Stockist Applicable For is a required field"),
      stockist_type: yup
        .object()
        .nullable()
        .test("module", "Stockist Type is required for Medvol Stockist.", function (value: any) {
          const { module } = this.parent;
          const isMedvolSelected = module?.some((item: any) => item?.module_desc === "Medvol");
          return !isMedvolSelected || !!value;
        }),
      stockist_default: yup
        .object()
        .nullable()
        .test("module", "Default Stockist is required for Medvol Stockist.", function (value: any) {
          const { module } = this.parent;
          const isMedvolSelected = module?.some((item: any) => item?.module_desc === "Medvol");
          return !isMedvolSelected || !!value;
        }),
      // districts_covered: yup
      //   .array()
      //   .nullable()
      //   .test("module", "Atleast one district should be selected for Medvol Stockist.", function (value: any) {
      //     const { module } = this.parent;
      //     const isMedvolSelected = module?.some((item: any) => item?.module_desc === "Medvol");
      //     return !isMedvolSelected || (Array.isArray(value) && value.length > 0);
      //   }),
      stk_dl_number: yup
        .string()
        .nullable()
        .max(150)
        .test("module", "Retail drug license number is required for PUC Stockist.", function (value: any) {
          const { module } = this.parent;
          const isPUCSelected = module?.some((item: any) => item?.module_desc === "PUC");
          return !isPUCSelected || !!value;
        }),
    };

    const isMedvolSelected = addEditFormData?.module?.some((item: any) => item?.module_desc === "Medvol");
    if (isMedvolSelected) {
      for (let i = 0; i < clusterFormSectionCounter; i++) {
        updatedSchemaObj[`distributionChannel__${i}`] = yup.string().nullable().notRequired();
        updatedSchemaObj[`paymentTermHdr__${i}`] = createValidationForClusterFields(
          `paymentTermHdr__${i}`,
          `${i === 0 ? "Payment Term is required for Medvol Stockist" : "Payment Term is required"}`
        );
        updatedSchemaObj[`clusterCode__${i}`] = createValidationForClusterFields(
          `clusterCode__${i}`,
          `${i === 0 ? "Cluster Code is required for Medvol Stockist" : "Cluster Code is required"}`
        );
        updatedSchemaObj[`margin__${i}`] = createValidationForClusterFields(
          `margin__${i}`,
          `${i === 0 ? "Margin is required for Medvol Stockist" : "Margin is required"}`
        );
        updatedSchemaObj[`districtsCovered__${i}`] = yup
          .array()
          .nullable()
          .test(
            "module",
            `${i === 0 ? "Atleast one district should be selected for Medvol Stockist." : "Atleast one district should be selected"}`,
            function (value: any) {
              const { module } = this.parent;
              const isMedvolSelected = module?.some((item: any) => item?.module_desc === "Medvol");
              return !isMedvolSelected || (Array.isArray(value) && value.length > 0);
            }
          );
      }
    }

    setSchemaObj(updatedSchemaObj);
  }, [clusterFormSectionCounter, addEditFormData]);

  // const fetchDistrictList = () => {
  //   getDistrictDetails()
  //     .then((res) => {
  //       setDistrictList(res.data.data || []);
  //     })
  //     .catch((err) => console.log(err));
  // };

  useEffect(() => {
    const fetchCounterTypes = async () => {
      try {
        const response = await getCounterTypes("?PUC=true");
        setModuleList(response?.data?.data || []);
      } catch (error) {
        console.error(error);
        toast({
          description: "Failed to fetch Modules",
          variant: "destructive",
        });
      }
    };

    fetchCounterTypes();
    // fetchDistrictList();

    if (mainFormEditMode && addEditFormData?.modules) {
      setStockistApplicableSections((prevState) =>
        Object.keys(prevState).reduce(
          (acc, key) => {
            acc[key] = addEditFormData.modules.some((item: any) => item?.module?.module_desc === key && item?.active_status === "Y");
            return acc;
          },
          {} as Record<string, boolean>
        )
      );

      const currentSelectedModules = addEditFormData?.modules.filter((item: any) => item?.active_status === "Y").map((item: any) => item?.module);

      dispatch(
        stockistActions.setFormData({
          value: currentSelectedModules,
          keyofForm: "module",
        })
      );

      setSelectedModules(currentSelectedModules);
    }
    if (mainFormEditMode && addEditFormData?.stockist_default) {
      const default_stockist =
        addEditFormData?.stockist_default === "N"
          ? defaultStockistOptions[1]
          : addEditFormData?.stockist_default === "Y"
            ? defaultStockistOptions[0]
            : null;
      dispatch(
        stockistActions.setFormData({
          value: default_stockist,
          keyofForm: "stockist_default",
        })
      );
    }

    if (mainFormEditMode && addEditFormData?.company) {
      setcompanydata(addEditFormData?.company);
    }
  }, []);

  // Define the schema for the form validation
  const schema = yup.object().shape({ ...schemaObj });

  // Define the schema for the form validation
  // const schema = yup.object().shape({ ...schemaObj });
  // let mainFormEditMode = true;

  const elnvExemptList = [
    { id: 1, elnv_exempt: "Yes" },
    { id: 2, elnv_exempt: "No" },
    // { id: 3, elnv_exempt: "exempt 3" },
    // Add more exemptions as needed
  ];

  const stockistTypeList = [
    { id: 2, stockist_type: "Regular" },
    { id: 1, stockist_type: "Skilled" },
    { id: 3, stockist_type: "Special" },
  ];

  const defaultStockistOptions = [
    { Name: "Yes", Id: "Yes" },
    { Name: "No", Id: "No" },
  ];

  const MedvolSection = [
    {
      label: "Stockist Type",
      type: "autocomplete",
      suggestionList: stockistTypeList,
      id: 15,
      keyofForm: "stockist_type",
      display_key: "stockist_type",
      unique_key: "id",
    },
    {
      label: "Default Stockist",
      type: "autocomplete",
      suggestionList: defaultStockistOptions,
      id: 16,
      keyofForm: "stockist_default",
      display_key: "Name",
      unique_key: "Id",
    },
    // {
    //   label: "Districts To Be Covered",
    //   type: "multiselect",
    //   suggestionList: districtList,
    //   id: 17,
    //   keyofForm: "districts_covered",
    //   display_key: "district_name",
    //   unique_key: "district_code",
    // },
  ];

  const PucSection = [
    {
      label: "Retail Drug License Number",
      type: "input",
      id: 20,
      keyofForm: "stk_dl_number",
    },
  ];

  const sectionConfig = {
    Medvol: {
      title: "Medvol",
      fields: MedvolSection,
      additionalComponents: [<StockistCluster companyData={companydata} />],
    },
    PUC: {
      title: "PUC",
      fields: PucSection,
      additionalComponents: [],
    },
  };

  const StockistLinkForm = [
    {
      label: "Select Company",
      type: "autocomplete",
      id: 2,
      keyofForm: "company",
      suggestionList: !isAddStockist
        ? companyList?.filter((item) => {
            const isCodePresent = selectedStockistData?.stockist_company_lnk?.some((link: any) => link?.company?.code === item?.code);
            return !isCodePresent;
          })
        : companyList,
      display_key: "name",
      unique_key: "code",
    },
    {
      label: "Plant Code",
      type: "input",
      id: 1,
      keyofForm: "plantCode",
    },
    {
      label: "Stockist Alias Code",
      type: "input",
      id: 3,
      keyofForm: "compStockistAliasCode",
      // disabled: mainFormEditMode ? true : false,
    },
    {
      label: "Stockist Code",
      type: "input",
      id: 4,
      keyofForm: "compStockistCode",
      disabled: stockistLinkEditMode ? true : false,
    },
    {
      label: "Operator Name",
      type: "input",
      id: 5,
      keyofForm: "operator_name",
    },
    {
      label: "Operator Mobile number",
      type: "input",
      id: 6,
      keyofForm: "operator_mobile",
    },

    {
      label: "Operator Email address",
      type: "e_mail",
      id: 7,
      keyofForm: "operator_email",
    },
    {
      label: "E-lnv. Exempt (Company)",
      type: "autocomplete",
      suggestionList: elnvExemptList,
      id: 10,
      keyofForm: "elnv_exempt",
      display_key: "elnv_exempt",
      unique_key: "id",
    },
    {
      label: "Reference Number",
      type: "input",
      id: 11,
      keyofForm: "reference_number",
    },
    {
      label: "Stockist Applicable For",
      type: "multiautocomplete",
      keyofForm: "module",
      id: 12,
      display_key: "module_desc",
      unique_key: "module_code",
      suggestionList: moduleList,
    },
  ];

  const fetchDetails = useCallback(async () => {
    if (!window.sessionStorage.getItem("stockistMasterId")) {
      navigate("/masters/stockists");
    }

    const queryParams = `?stockist_id=${window.sessionStorage.getItem("stockistMasterId")}`;

    let response: any = null;
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true&perPage=0&page=0`);
    if (response?.status !== 200) throw new Error();
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true&perPage=0&page=0`);

    // setIsLoading(false);
    dispatch(stockistActions.setSelectedStockistData(response?.data?.data?.data[0]));
  }, [dispatch]);

  const handleLinkCompany = async () => {
    const activeModules = new Set(addEditFormData?.module?.map((item: any) => item?.module_code) || []);

    const modulePayload = moduleList?.map((obj: any) => {
      const moduleData = addEditFormData?.modules?.find((module: any) => module?.module?.module_code === obj?.module_code);

      return {
        module_code: obj.module_code,
        active_status: activeModules.has(obj.module_code) ? "Y" : "N",
        ...(mainFormEditMode &&
          moduleData && {
            stkcmp_module_id: moduleData?.stkcmp_module_id,
          }),
      };
    });

    const sortedKeys = Object.keys(addEditFormData).sort((a, b) => {
      const indexA = parseInt(a.split("__")[1]);
      const indexB = parseInt(b.split("__")[1]);
      return indexA - indexB;
    });

    const keysToCheck = ["clusterCode", "paymentTermHdr", "distributionChannel", "margin", "districtsCovered"];

    const linkedClusterArray: any[] = [];

    sortedKeys.forEach((key) => {
      if (keysToCheck.includes(key.split("__")[0])) {
        const index = parseInt(key.split("__")[1]);

        if (!linkedClusterArray[index]) {
          linkedClusterArray[index] = {};
        }

        if (key.split("__")[0] === "clusterCode") {
          linkedClusterArray[index]["clusterCode"] = addEditFormData[key]?.cluster_code;
          linkedClusterArray[index]["cluster_name"] = addEditFormData[key]?.cluster_name;
        } else if (key.split("__")[0] === "paymentTermHdr") {
          linkedClusterArray[index]["paymentTermHdr"] = addEditFormData[key]?.id;
          linkedClusterArray[index]["payment_paymentTermCode"] = addEditFormData[key]?.paymentTermCode;
        } else if (key.split("__")[0] === "margin") {
          linkedClusterArray[index]["margin"] = addEditFormData[key]?.margin; //to be checked
        } else if (key.split("__")[0] === "distributionChannel") {
          linkedClusterArray[index]["distributionChannel"] = addEditFormData[key];
        } else if (key.split("__")[0] === "districtsCovered") {
          linkedClusterArray[index]["stockist_regioncovered"] = (addEditFormData[key] || []).map((district: { [key: string]: any }) => ({
            region_type: "D",
            region_code: district?.district_code || null,
            active_status: "Y",
            ...(mainFormEditMode
              ? {
                  stockistcluster_link_id:
                    district?.stockistcluster_link_id ??
                    addEditFormData?.stockistClusterLnk?.find(
                      (item: any) => item?.Cluster_Code?.cluster_code === linkedClusterArray[index]?.clusterCode
                    )?.id,
                  ...(district?.stockist_regioncovered_id ? { stockist_regioncovered_id: district?.stockist_regioncovered_id } : {}),
                }
              : {}),
          }));
        }
        linkedClusterArray[index].id = addEditFormData?.stockistClusterLnk?.find(
          (item: any) => item?.Cluster_Code?.cluster_code === linkedClusterArray[index]?.clusterCode
        )?.id;
        linkedClusterArray[index].active_status = "Y";
      }
    });

    addEditFormData?.stockistClusterLnk?.forEach((cluster: any) => {
      const isClusterAlreadyLinked = linkedClusterArray.some((obj) => obj?.clusterCode === cluster?.Cluster_Code?.cluster_code);

      if (!isClusterAlreadyLinked) {
        const delinkedClusters = {
          clusterCode: cluster?.Cluster_Code?.cluster_code,
          cluster_name: cluster?.Cluster_Code?.cluster_name,
          paymentTermHdr: cluster?.paymentTermHdr?.id,
          payment_paymentTermCode: cluster?.paymentTermHdr?.paymentTermCode,
          margin: cluster?.paymentTermHdr?.margin,
          distributionChannel: cluster?.distributionChannel,
          stockist_regioncovered: cluster?.stockistcluster_link_id?.map((region: any) => ({
            region_type: region?.region_type,
            region_code: region?.region_code?.district_code,
            active_status: "Y",
            stockistcluster_link_id: region?.stockistcluster_link_id,
            stockist_regioncovered_id: region?.stockist_regioncovered_id,
          })),
          id: cluster?.id,
          active_status: "N",
        };

        linkedClusterArray.push(delinkedClusters);
      }
    });

    const data: any = {
      company: addEditFormData?.company?.code, // Assuming company is represented by an ID
      company_name: addEditFormData?.company?.name,
      operator_name: addEditFormData?.operator_name,
      operator_mobile: addEditFormData?.operator_mobile || null,
      operator_email: addEditFormData?.operator_email,
      plantCode: addEditFormData?.plantCode,
      reference_number: addEditFormData?.reference_number,
      compStockistCode: addEditFormData?.compStockistCode,
      compStockistAliasCode: addEditFormData?.compStockistAliasCode,
      stockist_type: addEditFormData?.stockist_type?.stockist_type || null,
      stockist: selectedStockistData?.stockist_id,
      elnv_exempt: addEditFormData?.elnv_exempt?.elnv_exempt,
      module_codes: modulePayload || null,
      stockist_default: addEditFormData?.stockist_default?.Id === "Yes" ? "Y" : addEditFormData?.stockist_default?.Id === "No" ? "N" : null,
      stk_dl_number: addEditFormData?.stk_dl_number || null,
      stockistClusterLnk: linkedClusterArray,
      active_status: selectedStockistData?.active_status || "Y",
    };
    if (mainFormEditMode) {
      data.id = addEditFormData?.id;
    }

    try {
      setLoading(true);
      let resCompany;
      if (mainFormEditMode)
        resCompany = await editStockistCompany(
          {
            ...data,
            stockist: selectedStockistData?.stockist_id,
            medvol_code: selectedStockistData?.medvol_code,
            owner_name: selectedStockistData?.owner_name,
            firm_name: selectedStockistData?.firm_name, //to be checked
            city: selectedStockistData?.city?.city_code,
            email: selectedStockistData?.email,
          },
          selectedStockistData?.stockist_id
        );
      else
        resCompany = independentCompanyAdd
          ? await addStockistCompany(
              {
                ...data,
                stockist: selectedStockistData?.stockist_id,
                medvol_code: selectedStockistData?.medvol_code,
                active_status: "Y",
                owner_name: selectedStockistData?.owner_name,
                firm_name: selectedStockistData?.firm_name,
                city: selectedStockistData?.city?.city_code,
                email: selectedStockistData?.email,
              },
              selectedStockistData?.stockist_id
            )
          : null;

      if (resCompany?.status === 200) {
        dispatch(stockistActions.setShowStockistMainForm(false));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    dispatch(stockistActions.setLinkedCompaniesData(data));
    setTimeout(() => {
      fetchDetails();
    }, 1500);

    if (onSuccess) onSuccess();
  };

  const resetFieldsForNonSelectedModule = (nonSelectedModules: string[]) => {
    const updatedAddEditFormData = { ...addEditFormData };

    if (nonSelectedModules.includes("Medvol")) {
      const sortedKeys = Object.keys(addEditFormData).sort((a, b) => {
        const indexA = parseInt(a.split("__")[1]);
        const indexB = parseInt(b.split("__")[1]);
        return indexA - indexB;
      });

      const keysToCheck = ["clusterCode", "paymentTermHdr", "distributionChannel", "margin", "districtsCovered"];

      sortedKeys.forEach((key) => {
        if (keysToCheck.includes(key.split("__")[0])) {
          updatedAddEditFormData[key] = null;
        }
      });

      updatedAddEditFormData.stockist_type = null;
      updatedAddEditFormData.stockist_default = null;
    }

    if (nonSelectedModules.includes("PUC")) {
      updatedAddEditFormData.stk_dl_number = null;
    }

    if (Object.keys(updatedAddEditFormData)?.length) {
      dispatch(stockistActions.setStockistLinkFormFullObject(updatedAddEditFormData));
    }
  };

  const autocompleteHandler = (value: any, keyofForm: string) => {
    if (keyofForm === "company") {
      setcompanydata(value);
    }

    if (keyofForm === "module") {
      setStockistApplicableSections((prevState) => {
        const updatedState = { ...prevState };

        Object.keys(prevState).forEach((key) => {
          updatedState[key] = value.some((item: any) => item?.module_desc === key);
        });

        return updatedState;
      });

      // Determine modules to reset
      const hasMedvol = value.some((item: any) => item?.module_desc === "Medvol");
      const hasPUC = value.some((item: any) => item?.module_desc === "PUC");
      const nonSelectedModules = [];
      if (!hasMedvol) nonSelectedModules.push("Medvol");
      if (!hasPUC) nonSelectedModules.push("PUC");

      // Reset fields based on non-selected modules
      resetFieldsForNonSelectedModule(nonSelectedModules);
    }

    dispatch(
      stockistActions.setFormData({
        value: value,
        keyofForm: keyofForm,
      })
    );
  };

  return (
    <SmartForm isControlled={true} defaultValues={structuredClone(addEditFormData)} schema={schema} onFormSubmit={handleLinkCompany}>
      <div className="p-6 md:w-[51rem] relative w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
        {StockistLinkForm.map(({ label, type, id, placeholder, keyofForm, suggestionList, display_key, unique_key, disabled }: any) => {
          switch (type.toString().toLocaleLowerCase().trim()) {
            case "autocomplete":
              return (
                <Fragment key={id}>
                  <div className="w-[363px]">
                    <SmartForm.Autocomplete
                      fieldName={keyofForm}
                      label={label}
                      suggestionList={suggestionList || []}
                      displayKey={display_key}
                      disabled={disabled ? true : false}
                      isControlled={true}
                      value={
                        addEditFormData?.[keyofForm]
                          ? typeof addEditFormData?.[keyofForm] === "object"
                            ? addEditFormData?.[keyofForm]?.[display_key]
                            : addEditFormData?.[keyofForm]
                          : ""
                      }
                      uniqueKey={unique_key}
                      onChange={(value) => {
                        autocompleteHandler(value, keyofForm);
                      }}
                      placeholder={placeholder || ""}
                      key={id}
                      className="w-[363px]"
                    />
                  </div>
                </Fragment>
              );
            case "multiautocomplete":
              return (
                <Fragment key={id}>
                  <div className="w-[363px]">
                    <SmartForm.MultiAutocomplete
                      fieldName={keyofForm}
                      label={label}
                      suggestionList={suggestionList || []}
                      displayKey={display_key}
                      disabled={disabled ? true : false}
                      uniqueKey={unique_key}
                      controlledValue={selectedModules}
                      onChange={(data) => {
                        dispatch(
                          stockistActions.setFormData({
                            value: data,
                            keyofForm: keyofForm,
                          })
                        );
                        setSelectedModules(data);
                        autocompleteHandler(data, keyofForm);
                      }}
                      placeholder={placeholder || ""}
                      key={id}
                      className="w-[363px]"
                    />
                  </div>
                </Fragment>
              );
            default:
              return (
                <SmartForm.Input
                  type={type}
                  placeholder={placeholder || ""}
                  label={label}
                  disabled={disabled ? true : false}
                  key={id}
                  fieldName={keyofForm}
                  className="w-[363px]  mr-auto"
                  inputClassName="w-[363px]"
                  blurHandler={(value, keyofForm) => {
                    dispatch(
                      stockistActions.setFormData({
                        value: value,
                        keyofForm: keyofForm,
                      })
                    );
                  }}
                />
              );
          }
        })}

        {Object.entries(sectionConfig).map(([key, config]) => {
          if (!stockistApplicableSections[key]) return null;

          return (
            <div key={key} className="col-span-1 py-2 md:col-span-2">
              <Divider />
              <div className="relative grid grid-cols-1 py-6 md:grid-cols-2 gap-x-10 gap-y-4">
                <p
                  className={`absolute text-[#5E3CEC] focus:ring-0 ${
                    key === "Medvol" ? "left-[18.7rem]" : "left-[20rem]"
                  } top-6 text-[13px] font-bold`}
                >
                  {config.title}
                </p>

                {config.fields.map(({ label, id, type, placeholder, keyofForm, suggestionList, display_key, unique_key, disabled }: any) => {
                  switch (type.toString().toLocaleLowerCase().trim()) {
                    case "autocomplete":
                      return (
                        <div key={id} className="w-[363px]">
                          <SmartForm.Autocomplete
                            fieldName={keyofForm}
                            label={label}
                            suggestionList={suggestionList || []}
                            displayKey={display_key}
                            disabled={disabled ? true : false}
                            uniqueKey={unique_key}
                            onChange={(data) => {
                              dispatch(
                                stockistActions.setFormData({
                                  value: data,
                                  keyofForm: keyofForm,
                                })
                              );
                              autocompleteHandler(data, keyofForm);
                            }}
                            placeholder={placeholder || ""}
                            className="w-[363px]"
                          />
                        </div>
                      );
                    case "multiselect":
                      return (
                        <div key={id} className="w-[363px]">
                          <SmartForm.MultiSelect
                            fieldName={keyofForm}
                            label={label}
                            suggestionList={suggestionList || []}
                            displayKey={display_key}
                            disabled={disabled ? true : false}
                            uniqueKey={unique_key}
                            onChange={(data) => {
                              dispatch(
                                stockistActions.setFormData({
                                  value: data,
                                  keyofForm: keyofForm,
                                })
                              );
                              autocompleteHandler(data, keyofForm);
                            }}
                            placeholder={placeholder || ""}
                            className="w-[363px]"
                          />
                        </div>
                      );
                    case "input":
                      return (
                        <div key={id} className="w-[363px]">
                          <SmartForm.Input
                            type={type}
                            placeholder={placeholder || ""}
                            label={label}
                            disabled={disabled ? true : false}
                            fieldName={keyofForm}
                            className="w-[363px] mr-auto"
                            inputClassName="w-[363px]"
                            blurHandler={(value, keyofForm) => {
                              dispatch(
                                stockistActions.setFormData({
                                  value: value,
                                  keyofForm: keyofForm,
                                })
                              );
                            }}
                          />
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
              {config.additionalComponents.map((component, index) => (
                <div key={index} className="col-span-1 md:col-span-2">
                  {component}
                </div>
              ))}
            </div>
          );
        })}

        <div className="flex justify-end w-full col-span-1 gap-2 pt-4 md:col-span-2">
          <Button className="gap-2 border" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button type="submit" className="bg-[#7F56D9] text-white" isLoading={loading} loaderSize="small">
            {mainFormEditMode ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </SmartForm>
  );
};

// Define the Divider component
const Divider = () => <div className="h-[0.0625rem] w-[95%] bg-[#999999] mx-auto" />;

export default StockistLinkForm;
