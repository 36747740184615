import { forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components";
import FilterAccordion from "src/container/filter-accordion.component";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";

interface FilterItem {
  id?: string;
  name?: string;
  code?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add an index signature
}

interface Filters {
  company?: any[];
  city?: any[];
  state?: any[];
  district?: any[];
  status?: any[];
  stockistType?: any[];
}

interface StockistFilter {
  onCloseClick: () => void;
  companyDetails: FilterItem[];
  divisionDetails?: FilterItem[];
  linkDetails?: FilterItem[];
  cityDetails: FilterItem[];
  districtDetails: FilterItem[];
  stateDetails: FilterItem[];
  statusDetails: FilterItem[];
  stockistTypeDetails: FilterItem[];
}

const Divider = () => <div className="w-full h-[0.0625rem] bg-[#0000001a]" />;

const StockistsFilter = forwardRef<HTMLElement, StockistFilter>(
  ({ companyDetails, cityDetails, districtDetails, stateDetails, statusDetails, stockistTypeDetails }, ref) => {
    const { filters } = useSelector((state: RootState) => state.stockist);
    const dispatch = useDispatch();

    const filterCount = Object.keys(filters || {}).reduce((count, key) => {
      const filter = filters[key as keyof Filters];
      if (filter && Array.isArray(filter) && filter.length > 0) {
        return count + 1;
      }
      return count;
    }, 0);

    useEffect(() => {
      dispatch(stockistActions.setFilterCount(filterCount));
    }, [filterCount]);

  const companyFilterHandler = (details: FilterItem[]) => {
    dispatch(stockistActions.setCompanyFilter(details));
  };

    const districtFilterHandler = (details: FilterItem[]) => {
      dispatch(stockistActions.setDistrictFilter(details));
    };

    const stateFilterHandler = (details: FilterItem[]) => {
      dispatch(stockistActions.setStateFilter(details));
    };

    const cityFilterHandler = (details: FilterItem[]) => {
      dispatch(stockistActions.setCityFilter(details));
    };

    const statusFilterHandler = (details: FilterItem[]) => {
      dispatch(stockistActions.setStatusFilter(details));
    };

    const stockistTypeFilterHandler = (details: FilterItem[]) => {
      dispatch(stockistActions.setStockistTypeFilter(details));
    };

    const resetFilter = () => {
      dispatch(stockistActions.setCompanyFilter([]));
      dispatch(stockistActions.setCityFilter([]));
      dispatch(stockistActions.setDistrictFilter([]));
      dispatch(stockistActions.setStateFilter([]));
      dispatch(stockistActions.setStatusFilter([]));
      dispatch(stockistActions.setStockistTypeFilter([]));
      dispatch(stockistActions.setIsResetFilter(false));
    };

  return (
    <aside
      className="bg-white border-l-[1px] min-w-[20rem] overflow-y-auto max-w-[25rem] border-l-[#00000014] fixed px-8 text-[#49484C] text-xl font-semibold py-5 right-0  h-screen"
      ref={ref}
    >
      <div className="flex items-center justify-between gap-5 mt-3">
        <h3 className="whitespace-nowrap">Fetch filters</h3>
        <Button className="border py-1 bg-[#7F56D9] text-white" onClick={resetFilter}>
          Reset
        </Button>
      </div>

        {/* filters  */}
        <div className="flex flex-col ">
          <FilterAccordion
            uniqueKey="code"
            displayKey="shortname"
            onSelectingFilter={companyFilterHandler}
            title="Company Name"
            arrayToFilter={companyDetails}
            defaultValue={filters.company}
          />
          <Divider />

          <FilterAccordion
            uniqueKey="state_code"
            displayKey="state_name"
            title="State"
            defaultValue={filters.state}
            onSelectingFilter={stateFilterHandler}
            arrayToFilter={stateDetails}
          />
          <Divider />

          <FilterAccordion
            uniqueKey="district_code"
            displayKey="district_name"
            title="District"
            defaultValue={filters.district}
            onSelectingFilter={districtFilterHandler}
            arrayToFilter={districtDetails}
          />
          <Divider />

          <FilterAccordion
            uniqueKey="city_code"
            displayKey="city_name"
            title="City"
            defaultValue={filters.city}
            onSelectingFilter={cityFilterHandler}
            arrayToFilter={cityDetails}
          />
          <Divider />

          <FilterAccordion
            displayKey="stockist_type"
            title="Stockist Type"
            arrayToFilter={stockistTypeDetails}
            uniqueKey="id"
            onSelectingFilter={stockistTypeFilterHandler}
            defaultValue={filters.stockistType}
          />
          <Divider />

          <FilterAccordion
            displayKey="value"
            title="Active Status"
            arrayToFilter={statusDetails}
            uniqueKey="key"
            onSelectingFilter={statusFilterHandler}
            defaultValue={filters.status}
          />
        </div>
      </aside>
    );
  }
);

export default StockistsFilter;
