import clsx, { ClassValue } from "clsx";
import { checkMci } from "src/services/counter-api";
import { checkEmailStatus, checkGstStatus, checkMobileStatus } from "src/services/employee-api";
import { StockistAliasCode, StockistCode, uniqueMedvolCode, uniqueGstNumber } from "src/services/stockist-api";
import { twMerge } from "tailwind-merge";

export const switchString = (input: string) => {
  switch (input.toLowerCase()) {
    case "company_divisioncode":
      return "Division Code";
    case "chain_pharmacy":
      return "Chain Pharmacy";
    case "division_name":
      return "Division Name";
    case "speciality_id":
      return "Speciality";
    case "additional_info":
      return "Additional Info";
    case "company_productcode":
      return "Co. Product Code";
    case "counter_code":
      return "Counter Code";
    case "mci_register_number":
      return "MCI Register No.";
    // case "registration_date":
    //   return "Registeration Code";
    case "clinic_name":
      return "Clinic Name";
    case "counter_name":
      return "Doctor Name";
    case "item_cmpcode":
      return "Co. Item Code";
    case "description":
      return "Description";
    case "name":
      return "Name";
    case "item_factor":
      return "Item Factor";
    case "integral_quantity":
      return "Integral Qty.";
    case "ptr":
      return "PTR";
    case "min_qty":
      return "Min. Qty.";
    case "discountOnPTR_cp":
      return "Co. Discount PTR";
    case "created_by":
      return undefined;
    case "modified_by":
      return undefined;
    case "company_divisions":
      return "Co. Division";
    case "remarks":
      return "Remarks";
    case "company_code":
      return "Co. Code";
    case "modified_date":
      return "Modified Date";
    case "created_date":
      return "Created Date";
    case "gst_pc":
      return "GST ";
    case "price_identifier_cp":
      return "Co. Price Identifier";
    case "mrp":
      return "MRP";
    case "pts":
      return "PTS";
    case "item_type":
      return "Item Type";
    case "item_uom":
      return "Item UOM";
    case "item_molecule":
      return "Item Molecule";
    case "brand":
      return "Brand";
    case "po_itemcode":
      return "Product Item Code";
    case "item_code":
      return "Item Code";
    case "active_status":
      return undefined;
    case "division_code":
      return "Division Code";
    case "company_name":
      return "Company Name";
    case "cluster_name":
      return "Cluster Name";
    case "po_division_code":
      return "PO Division Code";
    case "enable_sms":
      return "Enable SMS";
    case "sms_value_limit":
      return "SMS Value Limit";
    case "order_value_email_alert":
      return "Order Value Email Alert";
    case "order_value_cap":
      return "Order Value Cap";
    case "product_draft_cap":
      return "Product Draft Cap";
    case "email_sent_to":
      return "Email Sent To";
    case "mode_of_acknowledgement":
      return "Mode of Acknowledgement";
    case "uin_validation":
      return "UIN Validation";
    case "alt_uin_description":
      return "Alt. UIN Description";
    case "alt_uin":
      return "Alt. UIN";
    case "uin_level":
      return "UIN Level";
    case "hybrid_hq_uin":
      return "Hybrid HQ UIN";
    case "no_of_days_for_ack":
      return "No. of days for ack.";
    case "po_generation":
      return "PO Generation";
    case "registration_date":
      return "Creation Date";
    case "drug_license_number":
      return "Drug License Number";
    case "manager_name":
      return "Manager Name";
    case "u_code":
      return undefined;
    case "uid":
      return undefined;
    case "gst_number":
      return "GST number";
    case "gst_available":
      return "GST Available";
    case "gst_status":
      return "GST Status";
    default:
      return input;
  }
};

export const stockistDatadisplaySequence = [
  "firm_name",
  "owner_name",
  "manager_name",
  "owner_mobile_no",
  "manager_mobile_no",
  "address",
  "city_name",
  "gst_available",
  "gst",
  "gst_status",
  "drug_licence",
  "food_licence",
  "email",
  "group_name",
  "medvol_code",
  "elnv_qualification",
  "elnv_invoice",
  "medvol_field",
];

export const attachmentBindingKeyKeyofFormMap: Record<string, string> = {
  counter_name: "counter_name",
  mobile_no: "mobile",
  address: "address",
  mci_register_number: "mci_register_number",
  clinic_name: "clinic_name",
  speciality: "speciality_id",
  pharmacy_name: "counter_name",
  drug_license_number: "drug_license_number",
  gst_number: "gst_number",
  hospital_name: "hospital_id",
};

export const queryParamsGenerator = (searchParams: URLSearchParams) => {
  // this function is used to generate query params for backend
  const queryParams = new URLSearchParams();
  const queryObj: { [key: string]: string } = Object.fromEntries(searchParams);

  const conversionObj: { [key: string]: string } = {
    c: "company_id",
    p: "company_productcode",
    d: "company_divisioncode",
  };

  Object.keys(queryObj).forEach((key) => {
    const code = queryObj[key].split("__")[1];
    queryParams.append(conversionObj[key], code);
  });

  return "?" + queryParams.toString();
};

export const queryProductParamsGenerator = (searchParams: URLSearchParams) => {
  // this function is used to generate query params for backend
  const queryParams = new URLSearchParams();
  const queryObj: { [key: string]: string } = Object.fromEntries(searchParams);

  const conversionObj: { [key: string]: string } = {
    c: "company_id",
    p: "company_productcode",
    // d: "company_divisioncode",
  };

  Object.keys(queryObj).forEach((key) => {
    const code = queryObj[key].split("__")[1];
    queryParams.append(conversionObj[key], code);
  });

  return "?" + queryParams.toString();
};

export const employeeQueryParamsGenerator = (searchParams: any) => {
  // this function is used to generate query params for backend
  const queryParams = new URLSearchParams();
  const queryObj: { [key: string]: string } = Object.fromEntries(searchParams);

  const conversionObj: { [key: string]: string } = {
    c: "company_id",
    psr: "psrcodenumeric_id",
    d: "division_id",
    emp: "empmaster_id",
  };

  Object.keys(queryObj).forEach((key) => {
    const code = queryObj[key].split("__")[1];
    queryParams.append(conversionObj[key], code);
  });

  return "?" + queryParams.toString();
};

// use for combining tailwind classes
export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));

export const findQueryNameCode = (arr: string[], target: string, Id: string): string | number => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].includes(target)) {
      const val = arr[i];
      if (Id === "code") {
        return val.split("=")[1];
      } else if (Id === "name") {
        return val.split("=")[0];
      }
      return i; // Return the index if the target string is found
    }
  }
  return -1; // Return -1 if the target string is not found in the array
};

export const constructUrlQueryWithRowData = (rowData: any) => {
  const companyData = rowData.company_code;
  const productData = rowData;
  // const divisionData =
  //   (rowData.company_divisions && rowData.company_divisions[0]) || {};

  const urlParams = [];

  if (companyData && companyData.name && companyData.code) {
    urlParams.push(`c=${companyData.name}__${companyData.code}`);
  }

  if (productData && productData.name && productData.company_productcode) {
    urlParams.push(`p=${productData.name}__${productData.company_productcode}`);
  }

  // if (divisionData.division_name && divisionData.company_divisioncode) {
  //   urlParams.push(
  //     `d=${divisionData.division_name}__${divisionData.company_divisioncode}`,
  //   );
  // }

  if (urlParams.length === 0) {
    return ""; // Return an empty string if there are no valid parameters
  }

  // Construct the URL query string by joining the valid parameters with '&'
  const urlQueryParams = `?${urlParams.join("&")}`;

  return urlQueryParams.replace(/ /g, "");
};

export const parseQueryString = (queryString: string): Record<string, string> => {
  const params: Record<string, string> = {};
  const pairs: string[] = queryString.split("&");

  for (const pair of pairs) {
    const [key, value] = pair.split("=");
    if (key && value) {
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  }

  return params;
};

export const createParamObject = (paramString: string | null) => {
  if (!paramString) return {};
  const params = paramString.split("&");

  let paramObject: { [key: string]: string } = {};
  params.forEach((param) => {
    const [key, value] = param.split("=");
    paramObject[key] = value;
  });
  return paramObject;
};

export const divisionExcludedKeys = [
  "company_division_uid",
  "created_by",
  "modified_by",
  "created_date",
  "modified_date",
  "ordervaluealert_mail",
  "enable_sms",
  "sms_valuelimit",
  "uin_level",
  "po_generate_ack_days",
  "po_generate_type_code",
  "is_pob",
];

export const divisionSwitchString = (input: string) => {
  switch (input.toLowerCase()) {
    case "company_divisioncode":
      return "Division Code";
    case "company_code":
      return "Company Name";
    case "company_cluster_name":
      return "Company Cluster Name";
    case "division_name":
      return "Division Name";
    case "division_shortcode":
      return "Division Shortcode";
    case "company_division_uid":
      return "Company Division UID";
    case "active_status":
      return "Active Status";
    case "created_by":
      return "Created By";
    case "created_date":
      return "Created Date";
    case "modified_by":
      return "Modified By";
    case "modified_date":
      return "Modified Date";
    case "ordervaluealert_mail":
      return "Order Value Email Alert";
    case "ordervalue_cap":
      return "Order Value Cap";
    case "enable_sms":
      return "Enable SMS";
    case "division_shortcode_po":
      return "Division Shortcode PO";
    case "sms_valuelimit":
      return "SMS Value Limit";
    case "draftcap_perc":
      return "Draft Cap Percentage";
    case "uin_validation_status":
      return "UIN Validation Status";
    case "alternate_uin_desc":
      return "Alt. UIN Description";
    case "alternate_uin_status":
      return "Alt. UIN Status";
    case "uin_level":
      return "UIN Level";
    case "hybrid_hq_uin":
      return "Hybrid HQ UIN";
    case "po_generate_ack_days":
      return "PO Generate Ack Days";
    case "po_generate_type_code":
      return "PO Generation";
    case "lowest_position":
      return "Lowest Position";
    case "highest_position":
      return "Highest Position";
    case "draph_drds_access":
      return "DRAPH DRDS Access";
    case "draph_indph_access":
      return "DRAPH INDPH Access";
    case "doc_type":
      return "Doc Type";
    case "sales_org":
      return "Sales Org";
    case "dist_channel":
      return "Dist Channel";
    case "division_sap":
      return "Division SAP";
    case "is_pob":
      return "POB Division";
    case "counter_type_id":
      return undefined;
    case "modules":
      return "Division Applicable For";
    case "all_products":
      return "All Products";
    case "placeorder_whatsapp":
      return "Placeorder Whatsapp";
    default:
      return input;
  }
};

export const isFieldRequired = (fieldName: string, schema: any): boolean => {
  if (!schema) return false;

  try {
    schema.validateSyncAt(fieldName, {});
    return false;
  } catch (error: any) {
    return error.errors?.[0]?.includes("required");
  }
};

type AnyObject = Record<string, any>;

export const removeEmptyValues = (obj: AnyObject): AnyObject => {
  if (Array.isArray(obj)) {
    // If it's an array, filter out null, undefined, and empty objects
    return obj.filter((item) => !(item === null || item === undefined || (typeof item === "object" && Object.keys(item).length === 0)));
  }

  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively call the function for nested objects
      obj[key] = removeEmptyValues(obj[key]);

      // Remove the key if all nested keys contain empty values
      if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
        delete obj[key];
      }
    } else {
      // Remove the key if the value is an empty string, null, undefined, or an empty object
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined || (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0)) {
        delete obj[key];
      }
    }
  }

  return obj;
};

export const removeEmptyValuesV2 = (obj: AnyObject): AnyObject => {
  if (Array.isArray(obj)) {
    // If it's an array, filter out null, undefined, and empty objects
    return obj.filter((item) => !(item === null || item === undefined || (typeof item === "object" && Object.keys(item).length === 0)));
  }
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively call the function for nested objects
      obj[key] = removeEmptyValues(obj[key]);
      // Remove the key if all nested keys contain empty values
      if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
        delete obj[key];
      }
    } else {
      // Remove the key if the value is null, undefined, or an empty object
      if (obj[key] === null || obj[key] === undefined || (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0)) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export async function mobileStatusHandler(value: any, context: any, query?: string) {
  if (!value) return true;
  try {
    const res = await checkMobileStatus(value, query);
    if (res?.status !== 200) throw new Error();
    if (res?.data?.mobile_no?.unique !== true) return context.createError({ message: res?.data?.mobile_no?.message });
  } catch (error) {
    return context.createError({ message: "Mobile Number is invalid" });
  }
  return true;
}

// return this.createError({ message: 'Dekhta h kya, mobile change kr' });

export async function mciNumberUniqueHandler(value: any, context: any) {
  if (!value) return true;
  try {
    const res = await checkMci(value);
    if (res?.status !== 200) throw new Error();
    if (res?.data?.isUnique !== true) return context.createError({ message: res?.data?.message });
  } catch (error) {
    return context.createError({ message: "Invalid Mci registration number" });
  }
  return true;
}

export async function GSTStatusHandler(value: any) {
  if (!value) return true;
  try {
    const res = await checkGstStatus(value);
    if (res?.status !== 200) throw new Error();
    if (res?.data?.mobile_no?.unique !== true) return false;
  } catch (error) {
    console.log(error);
    return false;
  }
  return true;
}

export async function medvolCodeHandler(value: any, context: any) {
  if (!value) return true;
  try {
    const res = await uniqueMedvolCode(value);
    if (res?.status !== 200) throw new Error();
    if (res?.data?.data?.unique !== true) return context.createError({ message: res?.data?.data?.message });
  } catch (error) {
    console.log(error);
    return context.createError({ message: "Medvol code is invalid" });
  }
  return true;
}

export async function stockistCodeHandler(value: any, companyCode: any, context: any, query: string) {
  // if (!value) return true;
  // try {
  //   const res = await StockistCode(value);
  //   console.log("res", res);
  //   if (res?.status !== 200) throw new Error();
  //   if (res?.data?.data?.unique !== true) return false;
  // } catch (error) {
  //   console.log(error);
  //   return false;
  // }
  // return true;

  if (!value) return true;
  try {
    const res = await StockistCode(value, companyCode, query);
    if (res?.status !== 200) throw new Error();
    if (res?.data?.success === false) return context.createError({ message: res?.data?.message });
    if (res?.data?.data?.unique !== true) {
      return context.createError({ message: res?.data?.data?.message });
    }
  } catch (error) {
    return context.createError({ message: "Stockist Code is invalid" });
  }
  return true;
}

export async function stockistAliasCodeHandler(value: any, companyCode: any, context: any, query: string) {
  if (!value) return true;
  try {
    const res = await StockistAliasCode(value, companyCode, query);
    console.log("res", res);
    if (res?.status !== 200) throw new Error();
    // if (res?.data?.data?.unique !== true) return false;
    if (res?.data?.success === false) return context.createError({ message: res?.data?.message });
    if (res?.data?.data?.unique !== true) return context.createError({ message: res?.data?.data?.message });
  } catch (error) {
    console.log(error);
    return context.createError({ message: "Stockist alias Code is invalid" });
    return false;
  }
  return true;
}

export async function emailStatusHandler(value: any, context: any) {
  if (!value) return true;
  try {
    const res = await checkEmailStatus(value);
    if (res?.status !== 200) throw new Error();
    if (res?.data?.email?.unique !== true) return context.createError({ message: res?.data?.email?.message });
  } catch (error) {
    return context.createError({ message: "Invalid Email" });
  }
  return true;
}

export async function gstNumberHandler(value: any, context: any, setCustomGstAlert: any, stockistId?: number) {
  if (!value) return true;
  try {
    const res = await uniqueGstNumber(value, stockistId ? `stockistId=${stockistId}` : null);
    if (res?.status !== 200) throw new Error();
    if (res?.data?.data?.unique === false) {
      setCustomGstAlert("");
      return context.createError({ message: res?.data?.data?.message });
    }
    if (res?.data?.data?.unique === true) {
      setCustomGstAlert(res?.data?.data?.message || "GST number is valid. You can proceed");
      return true;
    }
  } catch (error) {
    setCustomGstAlert("");
    return context.createError({ message: "Invalid GST" });
  }
  return true;
}

// let emailDebounceTimer: any = null;
// export async function emailStatusHandler(value: any) {
//   clearTimeout(emailDebounceTimer);
//   return new Promise((resolve, reject) => {
//     if (!value) resolve(true);
//     emailDebounceTimer = setTimeout(async () => {
//       try {
//         const res = await checkEmailStatus(value);
//         if (res?.status !== 200) throw new Error();
//         if (isProd) {
//           if (res?.data?.email_unique !== true) resolve(false);
//         } else {
//           if (res?.data?.email?.unique !== true) resolve(false);
//         }
//         resolve(true);
//       } catch (error) {
//         console.log(error);
//         reject(false);
//       }
//     }, 500); // Adjust debounce time as needed
//   });
// }

export const gcd: any = (a: any, b: any) => {
  while (b !== 0) {
    let temp = b;
    b = a % b;
    a = temp;
  }
  return a;
};

export const insertElementAtSpecificIndex = (arr: any[], element: any, index: number) => {
  let result = [];

  for (let i = 0; i < arr.length; i++) {
    if (i === index) result.push(element);
    result.push(arr[i]);
  }

  return result;
};

export const appendClientParams = (items: { [key: string]: string }[], paramName: string, codeName: string, codeId: string) => {
  let params = "";
  items?.forEach((item, index) => {
    // console.log("item :>> ", item);
    params += `${item[codeName].replace(/ /g, "+")}__${item[codeId]}`;
    const comma = index === items.length - 1 ? "" : ",";
    params += comma;
    console.log("params", params);
  });
  return params ? `${paramName}=${params}` : "";
};

export const appendParams = (items: { [key: string]: string }[], paramName: string, codeName: string) => {
  let params = "";
  items?.forEach((item, index) => {
    params += item[codeName];
    const comma = index === items.length - 1 ? "" : ",";
    params += comma;
  });
  return params ? `${paramName}=${params}` : "";
};

export const getArrayKey = (arr: any[], arrKeyTCheck: string, keyToFind: string) => {
  return arr.find((item) => item[arrKeyTCheck] === keyToFind);
};

export const deepCompare = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    // Convert types if necessary
    if (typeof obj1 === "string" && !isNaN(obj1 as any)) {
      obj1 = Number(obj1);
    }

    if (typeof obj2 === "string" && !isNaN(obj2 as any)) {
      obj2 = Number(obj2);
    }

    return obj1 === obj2;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!deepCompare(obj1[i], obj2[i])) {
        return false;
      }
    }

    return true;
  }

  for (const key in obj1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    if (!deepCompare(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const getValueFromNestedObject = (obj: any, keyPath: string): any => {
  // Split the keyPath by dots to get individual keys
  const keys: string[] = keyPath.split(".");

  // Use reduce to traverse through each key in the keyPath
  return keys.reduce((value: any, key: string) => {
    // Check if the current value is an object and contains the current key
    return value && typeof value === "object" ? value[key] : undefined;
  }, obj);
};

export const trimObjectValues = (obj: any): any => {
  if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      obj[key] = trimObjectValues(obj[key]); // Recursively trim nested objects
    }
  } else if (typeof obj === "string") {
    return obj.trim(); // Trim strings
  } else if (typeof obj === "number") {
    return Number(obj.toString().trim()); // Trim strings
  }
  return obj;
};
