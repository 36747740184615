/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useCallback, useEffect, useState } from "react";
import SmartForm from "src/container/smart-form.component";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import { generateUniqueID } from "src/constants/functions";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { addStockist, addStockistCompany, editStockist, queryStockist, getEInvAckType } from "src/services/stockist-api";
import { emailStatusHandler, medvolCodeHandler, mobileStatusHandler, gstNumberHandler } from "src/utils/utils";
import * as yup from "yup";
import { getMedvolUsers } from "src/services/employee-api";

interface StockistFormProps {
  onAddFormSubmit?: () => void;
  onCancelClick?: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AvailableGST = [
  { name: "Yes", id: "Yes" },
  { name: "No", id: "No" },
];

const GSTStatus = [
  { name: "Active", id: "Active" },
  { name: "Inactive", id: "Inactive" },
  { name: "Cancelled", id: "Cancelled" },
  { name: "Cancelled suo-moto", id: "Cancelled suo-moto" },
  { name: "Suspended", id: "Suspended" },
  { name: "Not Migrated", id: "Not Migrated" },
];

const EInvQualification = [
  { id: 1, elnv_qualification: "Yes", value: "yes" },
  { id: 2, elnv_qualification: "No", value: "no" },
  { id: 3, elnv_qualification: "Don't Know", value: "dont know" },
];

const StockistForm: React.FC<StockistFormProps> = ({ onCancelClick, onAddFormSubmit, loading, setLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { toast } = useToast();

  const { cityList, linkedCompaniesData, stockistFormData, mainFormEditMode } = useSelector((state: RootState) => state.stockist);

  const [medvolUsers, setMedvolUsers] = useState<any>([]);
  const [eInvAckType, setEInvAckType] = useState<any>([]);
  const [customGstAlert, setCustomGstAlert] = useState<string | null>(null);
  const [eInvAckDisabled, setEInvAckDisabled] = useState(false);
  const [disabledGst, setDisabledGst] = useState(false);

  console.log("cityList", cityList);

  const stockistSchema = yup.object().shape({
    firm_name: yup.string().required(),
    owner_name: yup.string().required(),
    manager_name: yup.string().notRequired(),
    owner_mobile_no: yup
      .number()
      .positive()
      .required()
      .transform((value) => {
        return isNaN(value) ? undefined : value;
      })
      .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10)
      .test("mobile-validation", "Invalid mobile number", (val, context) =>
        mobileStatusHandler(val, context, mainFormEditMode ? `user_type=stockist&user_code=${stockistFormData?.medvol_code}` : "")
      )
      .notOneOf([yup.ref("manager_mobile_no"), null], "Owner's mobile number must be different from the manager's mobile number"),
    manager_mobile_no: yup
      .number()
      .positive()
      .notRequired()
      .transform((value) => (isNaN(value) ? undefined : value))
      .test("len", "Mobile number should be 10 characters", (val) => !val || String(val).length === 10)
      .test(
        "mobile-validation",
        "Invalid mobile number",
        (val, context) =>
          !val || mobileStatusHandler(val, context, mainFormEditMode ? `user_type=stockist&user_code=${stockistFormData?.medvol_code}` : "")
      )
      .notOneOf([yup.ref("owner_mobile_no"), null], "Manager's mobile number must be different from the owner's mobile number"),
    address: yup.string().notRequired(),
    city: yup.object().required(),
    gst_available: yup.object().required("Available Gst is a required field"),
    gst: yup
      .string()
      .nullable()
      .matches(/^[0-9][a-zA-Z0-9]{14}$/, {
        message: "Invalid format. The value must start with a number and be 15 characters long.",
        excludeEmptyString: true,
      })
      .test("gst-number-validation", "Invalid GST Number", (val, context) => {
        return gstNumberHandler(val, context, setCustomGstAlert, mainFormEditMode ? stockistFormData?.stockist_id : null);
      })
      .test("gst_available", "GST status is required when GST is available.", function (value: any) {
        const { gst_available } = this.parent;
        if (gst_available && gst_available.id === "Yes" && !value) {
          return false;
        } else {
          return true;
        }
      }),
    gst_status: yup
      .object()
      .nullable()
      .test("gst_available", "GST status is required when GST is available.", function (value: any) {
        const { gst_available } = this.parent;
        if (gst_available && gst_available.id === "Yes" && !value) {
          return false;
        } else {
          return true;
        }
      }),
    // // Add more keys as needed
    drug_licence: yup.string().notRequired(),
    food_licence: yup.string().notRequired(),
    email: yup
      .string()
      .required()
      .email("Invalid email format")
      .test("managerEmailId", "Invalid email Id", (val, context) => {
        if (typeof val === "string") emailStatusHandler(val, context);
        return true;
      }),
    group_name: yup.string().notRequired(),
    medvol_code: yup
      .string()
      .required()
      .test("medvol_code-validation", "Invalid medvol code", (val, context) => {
        if (mainFormEditMode && val === stockistFormData?.medvol_code) return true;
        return medvolCodeHandler(val, context);
      }),
    elnv_qualification: yup.object().required("EInv. Qualification is a required field"),
    elnv_invoice: yup
      .object()
      .nullable()
      .test("elnv_qualification", "E-Invoice Ack. is required when E-lnv. Qualification is Yes.", function (value: any) {
        const { elnv_qualification } = this.parent;
        if (elnv_qualification && elnv_qualification.value === "yes" && !value) {
          return false;
        } else {
          return true;
        }
      }),
    reference_number: yup.string().required(),
  });

  // Define the schema for the form validation
  // let mainFormEditMode = true;

  const StockistForm = [
    {
      label: "Firm Name",
      type: "input",
      id: 2,
      keyofForm: "firm_name",
      // disabled: mainFormEditMode ? true : false,
    },
    {
      label: "Owner's Name",
      type: "input",
      id: 1,
      keyofForm: "owner_name",
    },
    {
      label: "Manager's Name",
      type: "input",
      id: 3,
      keyofForm: "manager_name",
      // disabled: mainFormEditMode ? true : false,
    },
    {
      label: "Owner’s Mobile number",
      type: "mobile_no",
      id: 4,
      keyofForm: "owner_mobile_no",
    },
    {
      label: "Manager’s Mobile number",
      type: "mobile_no",
      id: 5,
      keyofForm: "manager_mobile_no",
    },
    {
      label: "Address",
      type: "input",
      id: 6,
      keyofForm: "address",
    },
    {
      label: "City",
      type: "autocomplete",
      id: 9,
      keyofForm: "city",
      suggestionList: cityList || [],
      display_key: "city_name",
      uniqueKey: "city_id",
    },
    {
      // Field for Reference Number
      label: "Available Gst",
      type: "autocomplete",
      id: 232,
      suggestionList: AvailableGST,
      display_key: "name",
      uniqueKey: "id",
      keyofForm: "gst_available",
      size: "full",
    },
    {
      label: "GST Number",
      type: "input",
      id: 8,
      keyofForm: "gst",
      // disabled: mainFormEditMode ? true : false,
      disabled: disabledGst,
    },
    {
      label: "GST status",
      type: "autocomplete",
      id: 421,
      suggestionList: GSTStatus,
      keyofForm: "gst_status",
      display_key: "name",
      uniqueKey: "id",
      disabled: disabledGst,
    },
    {
      label: "Drug License Number",
      type: "input",
      id: 9,
      keyofForm: "drug_licence",
      // disabled: mainFormEditMode ? true : false,
    },
    {
      label: "Food License Number",
      type: "input",
      id: 10,
      keyofForm: "food_licence",
      // disabled: mainFormEditMode ? true : false,
    },
    { label: "Email address", type: "email_id", id: 14, keyofForm: "email" },
    {
      label: "Group Name",
      type: "input",
      id: 16,
      keyofForm: "group_name",
      // disabled: mainFormEditMode ? true : false,
    },
    {
      label: "Medvol Code",
      type: "input",
      id: 13,
      keyofForm: "medvol_code",
      disabled: mainFormEditMode ? true : false,
    },
    {
      label: "E-lnv. Qualification",
      type: "autocomplete",
      suggestionList: EInvQualification,
      id: 11,
      keyofForm: "elnv_qualification",
      display_key: "elnv_qualification",
      uniqueKey: "value",
    },
    {
      label: "E-Invoice Ack.",
      type: "autocomplete",
      suggestionList: eInvAckType,
      id: 12,
      display_key: "einvack_type_desc",
      keyofForm: "elnv_invoice",
      uniqueKey: "einvack_type_code",
      disabled: eInvAckDisabled,
    },

    {
      label: "Company Linking",
      type: "clickabletext",
      id: 15,
      keyofForm: "stockist_company_lnk",
      disabled: mainFormEditMode ? true : false,
    },

    {
      label: "Reference Number",
      type: "text",
      id: 16,
      keyofForm: "reference_number",
    },
    {
      label: "Medvol Field",
      type: "autocomplete",
      keyofForm: "medvol_field",
      id: 17,
      display_key: "Name",
      suggestionList: medvolUsers,
    },
  ];

  const fetchDetails = useCallback(async () => {
    if (!window.sessionStorage.getItem("stockistMasterId")) {
      navigate("/masters/stockists");
    }

    const queryParams = `?stockist_id=${window.sessionStorage.getItem("stockistMasterId")}`;

    let response: any = null;
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true&perPage=0&page=0`);
    if (response?.status !== 200) throw new Error();
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true&perPage=0&page=0`);

    // setIsLoading(false);
    dispatch(stockistActions.setSelectedStockistData(response?.data?.data?.data[0]));
  }, [dispatch]);

  console.log("addEditFormData?.stockist_id", stockistFormData);

  const handleCreateStockist = async (data: any) => {
    console.log("data", data, stockistFormData);

    const body = {
      owner_name: stockistFormData?.owner_name,
      owner_mobile_no: stockistFormData?.owner_mobile_no,
      medvol_code: stockistFormData?.medvol_code,
      firm_name: stockistFormData?.firm_name,
      manager_name: stockistFormData?.manager_name,
      manager_mobile_no: stockistFormData?.manager_mobile_no || null,
      address: stockistFormData?.address,
      gst: stockistFormData?.gst,
      gst_available: stockistFormData?.gst_available?.id || "no",
      gst_status: stockistFormData?.gst_status?.id || null,
      drug_licence: stockistFormData?.drug_licence,
      food_licence: stockistFormData?.food_licence,
      email: stockistFormData?.email,
      group_name: stockistFormData?.group_name,
      u_code: mainFormEditMode ? stockistFormData?.u_code : generateUniqueID(),
      city: stockistFormData?.city?.city_code, // Assuming city is represented by an ID
      elnv_qualification: stockistFormData?.elnv_qualification?.elnv_qualification,
      elnv_invoice: stockistFormData?.elnv_qualification?.elnv_qualification === "Yes" ? stockistFormData?.elnv_invoice?.einvack_type_code : 0,
      stockist_id: mainFormEditMode ? stockistFormData?.stockist_id : null,
      reference_number: data?.reference_number,
      staff_position_id: stockistFormData?.medvol_field?.staff_position_id,
      medvol_field_uid: stockistFormData?.medvol_field?.uid,
      active_status: stockistFormData?.active_status,
    };

    console.log("body", body);

    try {
      if (!mainFormEditMode) {
        setLoading(true);

        const res = await addStockist(body);

        if (res?.status !== 200) throw new Error();
        toast({
          description: "Stockist created successfully",
        });
        if (linkedCompaniesData.length > 0) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          const resCompany = await addStockistCompany(
            {
              ...linkedCompaniesData?.[0],
              stockist: res?.data?.eventCreated?.data?.stockist_id,
              medvol_code: stockistFormData?.medvol_code,
              active_status: "Y",
              owner_name: res?.data?.eventCreated?.data?.owner_name,
              firm_name: res?.data?.eventCreated?.data?.firm_name,
              city: res?.data?.eventCreated?.data?.city,
              email: res?.data?.eventCreated?.data?.email,
            },
            res?.data?.eventCreated?.data?.stockist_id
          );
          if (resCompany?.status !== 200) throw new Error();
          toast({
            description: "Company linked successfully",
          });
        }
        setTimeout(() => {
          window.sessionStorage.setItem("stockistMasterId", res?.data?.eventCreated?.data?.stockist_id ?? "");
          dispatch(stockistActions.setSelectedStockistData({}));
          navigate(`/Masters/Stockists/Details?id=${res?.data?.eventCreated?.data?.stockist_id}`);
        }, 2000);
        if (onAddFormSubmit) {
          onAddFormSubmit();
        }
      } else {
        setLoading(true);
        console.log("went to edit here", body);
        const res = await editStockist(body, stockistFormData?.stockist_id);

        if (res?.status !== 200) throw new Error();
        toast({
          description: "Stockist updated successfully",
        });
        setTimeout(() => {
          if (onAddFormSubmit) onAddFormSubmit();

          fetchDetails();
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      toast({
        description: "Stockist not created",
      });
      onCancelClick && onCancelClick();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMedvolUsers("?ffStaff=true")
      .then((res) => setMedvolUsers(res?.data || []))
      .catch((err) => console.log(err));

    getEInvAckType()
      .then((res) => setEInvAckType(res?.data?.data || []))
      .catch((err) => console.log(err));

    if (stockistFormData?.gst_available === "No" || stockistFormData?.gst_available?.id === "No") {
      setDisabledGst(true);
    }
    if (
      ["No", "Don't Know"].includes(stockistFormData?.elnv_qualification) ||
      ["No", "Don't Know"].includes(stockistFormData?.elnv_qualification?.elnv_qualification)
    ) {
      setEInvAckDisabled(true);
    }
    if (mainFormEditMode) {
      const gst_available = AvailableGST.find((item) => item.name === stockistFormData?.gst_available) || null;
      const elnv_qualification = EInvQualification.find((item) => item.elnv_qualification === stockistFormData?.elnv_qualification) || null;
      const gst_status = GSTStatus.find((item) => item.name === stockistFormData?.gst_status) || null;
      const updatedStockistFormData = {
        ...stockistFormData,
        gst_available,
        gst_status,
        elnv_qualification,
      };
      dispatch(stockistActions.setStockistFullFormData(updatedStockistFormData));
    }
  }, []);

  const autocompleteHandler = (data: any, keyofForm: string) => {
    switch (keyofForm) {
      case "elnv_qualification":
        if (data?.value === "yes") {
          setEInvAckDisabled(false);
        } else {
          setEInvAckDisabled(true);
          dispatch(
            stockistActions.setStockistFormData({
              value: null,
              keyofForm: "elnv_invoice",
            })
          );
        }
        break;

      case "gst_available":
        if (data?.id === "Yes") {
          setDisabledGst(false);
        } else {
          setDisabledGst(true);
          setCustomGstAlert("");
          dispatch(
            stockistActions.setStockistFormData({
              value: "",
              keyofForm: "gst",
            })
          );
          dispatch(
            stockistActions.setStockistFormData({
              value: null,
              keyofForm: "gst_status",
            })
          );
        }
        break;
    }
  };

  return (
    <SmartForm
      defaultValues={{
        ...stockistFormData,
        stockist_company_lnk: linkedCompaniesData?.length,
      }}
      schema={stockistSchema}
      isControlled={true}
      onFormSubmit={handleCreateStockist}
    >
      <div className="p-6 md:w-[51rem] relative w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
        {StockistForm.map(({ label, type, id, placeholder, keyofForm, suggestionList, display_key, uniqueKey, disabled }: any) => {
          switch (type.toString().toLocaleLowerCase().trim()) {
            case "autocomplete":
              return (
                <Fragment key={id}>
                  <div className="w-[363px]">
                    <SmartForm.Autocomplete
                      fieldName={keyofForm}
                      label={label}
                      suggestionList={suggestionList || []}
                      displayKey={display_key}
                      disabled={disabled ? true : false}
                      uniqueKey={uniqueKey}
                      onChange={(data) => {
                        dispatch(
                          stockistActions.setStockistFormData({
                            value: data,
                            keyofForm: keyofForm,
                          })
                        );
                        autocompleteHandler(data, keyofForm);
                      }}
                      placeholder={placeholder || ""}
                      key={id}
                      className="w-[363px]"
                    />
                  </div>
                </Fragment>
              );
            case "clickabletext":
              return (
                <Fragment key={id}>
                  <div className="w-[363px] relative">
                    <p
                      onClickCapture={
                        linkedCompaniesData?.length === 1
                          ? () => {}
                          : () => {
                              dispatch(stockistActions.linkClusterCompanyFormData({ status: true }));
                              dispatch(stockistActions.setStockistLinkFormFullObject({}));
                              if (mainFormEditMode) {
                                dispatch(stockistActions.setStockistLinkEditMode(true));
                              }
                            }
                      }
                      className={`absolute right-4 top-6  underline ${
                        linkedCompaniesData?.length === 1 ? "text-gray-500" : mainFormEditMode ? "text-gray-500 pointer-events-none" : "text-blue-500"
                      }`}
                      role="button"
                    >
                      Link
                    </p>
                    <SmartForm.Input
                      type={type}
                      placeholder={placeholder || ""}
                      label={label}
                      key={id}
                      fieldName={keyofForm}
                      className="w-[363px]  mr-auto"
                      inputClassName="w-[363px]"
                      disabled={true}
                    />
                  </div>
                </Fragment>
              );
            default:
              switch (keyofForm) {
                case "gst":
                  return (
                    <Fragment key={id}>
                      <div>
                        <SmartForm.Input
                          type={type}
                          placeholder={placeholder || ""}
                          label={label}
                          disabled={disabled ? true : false}
                          key={id}
                          fieldName={keyofForm}
                          className="w-[363px]  mr-auto"
                          inputClassName="w-[363px]"
                          blurHandler={(value, keyofForm) => {
                            dispatch(
                              stockistActions.setStockistFormData({
                                value: value,
                                keyofForm: keyofForm,
                              })
                            );
                            if (keyofForm === "gst" && !value) {
                              setCustomGstAlert("");
                            }
                          }}
                        />
                        {customGstAlert && <p className="px-2 py-1 text-sm text-yellow-600">{customGstAlert}</p>}
                      </div>
                    </Fragment>
                  );

                default:
                  return (
                    <SmartForm.Input
                      type={type}
                      placeholder={placeholder || ""}
                      label={label}
                      disabled={disabled ? true : false}
                      key={id}
                      fieldName={keyofForm}
                      className="w-[363px]  mr-auto"
                      inputClassName="w-[363px]"
                      blurHandler={(value, keyofForm) => {
                        dispatch(
                          stockistActions.setStockistFormData({
                            value: value,
                            keyofForm: keyofForm,
                          })
                        );
                      }}
                    />
                  );
              }
          }
        })}
        <div className="col-span-1 md:col-span-2">
          {/* {true && <EmployeeDivision headerQuarterError={"headerQuarterError"} setHeaderQuarterError={"setHeaderQuarterError"} />} */}
        </div>

        <div className="flex justify-end w-full col-span-1 gap-2 pt-4 md:col-span-2">
          <Button className="gap-2 border" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button type="submit" className="bg-[#7F56D9] text-white" isLoading={loading} loaderSize="small">
            {mainFormEditMode ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </SmartForm>
  );
};

export default StockistForm;
