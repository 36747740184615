import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Spinner } from "src/components";
import Loader from "src/components/ui/loader.component";
import { generateUniqueID } from "src/constants/functions";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { getStockist, postPlaceOrder, updateExDefault, uploadAttachment, verifyOrderInvoice } from "src/services/order-api";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import Button from "../../components/Button";
import OrderReviewProductCard from "./OrderReviewProductCard";

interface Item {
  companyProducts?: {
    item_division: {
      cluster_code: {
        cluster_code: string;
        cluster_name: string;
      };
    }[];
  };
}

const OrderReviewScreen = () => {
  const { toast } = useToast();

  const { locationCode } = useParams();
  const { cartDataObj, selectedCounterData } = useSelector((state: RootState) => state.ord);
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);
  const { currentPsr } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [remark, setRemark] = useState("");
  const [placingOrder, setPlacingOrder] = useState(false);
  const [stockist, setStockist] = useState<any>(null);
  const [selectedStockist, setSelectedStockist] = useState<any[]>([]);
  const [isLoading] = useState(false);
  const [orderType, setOrderType] = useState<any>("regular");
  const [clusterCodes, setClusterCodes] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null); // State to store the selected file
  const [stockistFile, setStockistFile] = useState<File | null>(null); // State to store the selected file
  const [uploading, setUploading] = useState(false); // State to handle loading state
  const [uploaded, setUploaded] = useState(false);
  const [filePath, setFilePath] = useState<{
    counter_copy_path: string | "";
    stockist_copy_path: string | "";
  }>({
    counter_copy_path: "",
    stockist_copy_path: "",
  });

  const [isCheckingPendingInvoices, setIsCheckingPendingInvoices] = useState(false);

  const [showPendingInvoices, setShowPendingInvoices] = useState(false);

  const dispatch = useDispatch();

  const handleRemarkChange = (e: any) => {
    setRemark(e.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
  };

  const handleStockistFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setStockistFile(selectedFile);
  };

  const handleUpload = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!file) {
      toast({ description: "Please select a file before uploading.", variant: "destructive" });
      return;
    }

    const formData = new FormData();
    formData.append("counter_copy_path", file);
    if (stockistFile) {
      formData.append("stockist_copy_path", stockistFile);
    }

    setUploading(true);

    try {
      const response = await uploadAttachment(formData);

      if (response?.status !== 200) {
        throw new Error("Failed to upload file. Please try again.");
      }

      // setFilePath({
      //   counter_copy_path: response?.data?.counter_copy_path,
      //   stockist_copy_path: response?.data?.stockist_copy_path,
      // });

      const data = response?.data?.data;
      setFilePath({
        counter_copy_path: data?.file_path?.counter_copy_path,
        stockist_copy_path: data?.file_path?.stockist_copy_path,
      });
      setUploaded(true);
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const handleStockistChange = (value: any, type: string) => {
    // Ensure value is an array, otherwise wrap it in an array
    const valueArray = Array.isArray(value) ? value : [value];

    switch (type) {
      case "add": {
        setSelectedStockist((prev: any[]) => {
          const newSelection = valueArray.filter(
            (item: any) => !prev.some((prevItem: any) => prevItem.counter_stockist_Lnk_Id === item.counter_stockist_Lnk_Id)
          );
          return [...prev, ...newSelection];
        });

        const selectedStockistIds: number[] = [];
        const selectedStockistClusterCodes: number[] = [];
        valueArray.forEach((item: any) => {
          selectedStockistIds.push(item?.counter_stockist_Lnk_Id);
          selectedStockistClusterCodes.push(item?.cluster_code);
        });

        setStockist((prev: any) => {
          return prev.map((item: any) => {
            if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.counter_stockist_Lnk_Id)) {
              item.disabled = true;
            }
            return item;
          });
        });

        break;
      }
      case "remove": {
        setSelectedStockist((prev: any[]) => {
          return prev.filter((item) => !valueArray.some((removeItem: any) => removeItem.counter_stockist_Lnk_Id === item.counter_stockist_Lnk_Id));
        });

        const selectedStockistIds: number[] = [];
        const selectedStockistClusterCodes: number[] = [];
        valueArray.forEach((item: any) => {
          selectedStockistIds.push(item?.counter_stockist_Lnk_Id);
          selectedStockistClusterCodes.push(item?.cluster_code);
        });

        setStockist((prev: any) => {
          return prev.map((item: any) => {
            if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.counter_stockist_Lnk_Id)) {
              item.disabled = false;
            }
            return item;
          });
        });
        break;
      }
    }
  };

  const subtotal = Object.values(cartDataObj)?.reduce((acc: number, item: any) => acc + Number(item?.companyProducts?.ptr) * item?.cartQty, 0);
  const totalItems = Object.values(cartDataObj)?.reduce((acc: number, item: any) => acc + item?.cartQty, 0);

  const getTotalDiscount = (): number => {
    let totalDiscount = 0;

    Object.values(cartDataObj)?.forEach((item: any) => {
      const ptr = Number(item?.companyProducts?.ptr || 0);
      const cartQty = Number(item?.cartQty || 0);
      const priceIdentifier = item?.priceldentifier?.priceldentifier_id;
      const slab = item?.counter_product_slab?.[0];

      if (!slab) return;

      switch (priceIdentifier) {
        case 2: // Scheme Discount
          totalDiscount += ptr * Math.floor(cartQty / (slab.schemeqty_cp || 1)) * (slab.quantity_cp || 0);
          break;

        case 3: // Fixed Price Discount
          totalDiscount += Number(slab.fixedprice_cp || 0) * cartQty;
          break;

        case 1: // Percentage Discount
        default:
          totalDiscount += (ptr * cartQty * Number(slab.discountonptr_cp || 0)) / 100;
          break;
      }
    });

    return Number(totalDiscount.toFixed(2));
  };

  const now = new Date();
  const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC +5:30
  const istTime = new Date(now.getTime() + istOffset);
  const istISOString = istTime.toISOString().slice(0, -1); // Removes the 'Z' to avoid confusion

  const handlePlaceOrder = async () => {
    if (!selectedStockist) {
      toast({ description: "Please select a stockist!", variant: "destructive" });
      return;
    }
    if (Object.keys(cartDataObj)?.length === 0) {
      toast({ description: "Please add products to the cart!", variant: "destructive" });
      return;
    }
    if (!orderType) {
      toast({ description: "Please select an order mode!", variant: "destructive" });
      return;
    }

    if (file && !uploaded) {
      toast({ description: "Please upload the file before placing the order!", variant: "destructive" });
      return;
    }

    const items: Item[] = Object.values(cartDataObj);

    // Check if all clusters have a selected stockist
    for (const item of items) {
      const cluster_ucode = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_code;
      const cluster_name = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_name;
      const stockistForCluster = selectedStockist.find((stockist: any) => stockist.cluster_code === cluster_ucode);
      if (!stockistForCluster) {
        toast({
          description: `Stockist for cluster "${cluster_name}" is not selected.`,
          variant: "destructive",
        });
        return;
      }
    }

    const groupedOrderGroups: any = {};
    Object.values(cartDataObj)?.forEach((item: any, index: number) => {
      const ordergroup_id = item?.companyProducts?.item_division[0]?.ordergroup_id?.ordergroup_id;
      const cluster_ucode = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_code;
      const cmp_countertag_id = item?.counter_ff_div_id?.cmp_countertag_id;

      const groupKey = cmp_countertag_id ? `${cmp_countertag_id}_${cluster_ucode}` : `${ordergroup_id}_${cluster_ucode}`;

      if (!groupedOrderGroups[groupKey]) {
        const stockistForCluster = selectedStockist.find((stockist) => stockist.cluster_code === cluster_ucode);

        if (stockistForCluster) {
          groupedOrderGroups[groupKey] = {
            stockist_id: stockistForCluster?.id,
            stockist_name: stockistForCluster?.name,
            company_stockist_code: stockistForCluster?.company_stockist_code,
            cluster_code: cluster_ucode,
            cluster_ucode: item?.companyProducts?.item_division[0]?.cluster_code?.Cluster_UniqueId,
            ordergroup_id: ordergroup_id,
            cmp_countertag_id: cmp_countertag_id,
            cndn_remarks_code: item?.companyProducts?.item_division[0]?.ordergroup_id?.order_cndn_remarks?.cndn_remarks_code,
            order_mode: orderType,
            headquarter_code: item?.counter_ff_div_id?.position_code?.emp_position_devrel[0]?.headQuarter_code,
            bill_amount: subtotal,
            discount_amount: getTotalDiscount(),
            orderProducts: [], // Initialize empty array
          };
        }
      }

      // Add the product to the `orderProducts` array for the groupKey
      if (groupedOrderGroups[groupKey]) {
        groupedOrderGroups[groupKey].orderProducts.push({
          counter_product: item?.counter_product_lnk_id || "",
          quantity: item?.cartQty || 0,
          sequence_number: index + 1,
          ucode: generateUniqueID(),
          company_division_code: item?.counter_ff_div_id?.company_division_code?.company_divisioncode || "",
          item_division_id: item?.counter_ff_div_id?.company_division_code?.company_divisioncode || "",
          product_name: item?.companyProducts?.name || "",
          item_code: item?.companyProducts?.item_code || "",
          ptr: item?.companyProducts?.ptr || 0,
        });
      }
    });

    const clusters_ordergroup_data = Object.values(groupedOrderGroups);

    try {
      setPlacingOrder(true);
      const payload = {
        data: {
          company_code: Number(selectedCounterData?.company_code),
          counter_code: selectedCounterData?.counter_location?.counter_hdr_id?.counter_code,
          counter_ucode: selectedCounterData?.counter_location?.counter_hdr_id?.uid,
          counter_company_location_id: selectedCounterData?.counter_company_location_id,
          created_by: idTokenDetails?.sub || "",
          created_date: istISOString,
          source: "Mobile App",
          latitude: 40.7128,
          longitude: -74.006,
          actor_name: idTokenDetails?.name || "",
          actor_code: currentPsr?.emp_position_hdr?.position_code || "",
          actor_type: idTokenDetails?.["custom:role"],
          user_ucode: idTokenDetails?.["custom:ucode"] || "",
          cash_discount: 0,
          ucode: generateUniqueID(),
          order_freetxt_remarks: remark,
          remarkby_type: "MV",
          // position_code: currentPsr?.emp_position_hdr?.position_code || "9214LUPEDPXHYJ",
          location_code: selectedCounterData?.counter_location?.location_code,
          stockist_copy_path: filePath?.stockist_copy_path,
          counter_copy_path: filePath?.counter_copy_path,
          clusters_ordergroup: clusters_ordergroup_data,
        },
        event_type: "ORDER_ADD",
      };

      const res = await postPlaceOrder(payload, selectedCounterData?.company_code);
      if (res?.status !== 201) throw new Error(res?.data?.message);
      if (res?.status === 201) {
        const selectedStockistIds: any = [];

        payload?.data?.clusters_ordergroup?.forEach((item: any) => {
          const stockist = selectedStockist?.find((stockist: any) => stockist?.id === item?.stockist_id);
          if (stockist?.counter_stockist_Lnk_Id) {
            selectedStockistIds.push(stockist.counter_stockist_Lnk_Id);
          }
        });

        const selectedStockistIdsString = selectedStockistIds.join(",");

        // Construct the payload
        const counter_company_location_id = selectedCounterData?.counter_company_location_id;

        // Call the function with separate parameters
        const res = await updateExDefault(counter_company_location_id, selectedStockistIdsString);
        console.log(res);
      }
      navigate("/transactions/orders/confirmation");
      dispatch(ordActions.setOrderDetails(res?.data?.data));
      setOrderType(null);
      // console.log(payload);
      setPlacingOrder(false);
      setSelectedStockist([]);
    } catch (error: any) {
      setPlacingOrder(false);
      console.log(error);
      toast({
        description: error?.message || "Failed to place order",
        variant: "destructive",
      });
    }
  };

  // const filterClusterProducts = () => {
  //   const clusterProductsArray = clusterCodes.map(
  //     (item: any) =>
  //       Object.values(cartDataObj)?.filter((product: any) => product?.companyProducts?.item_division[0]?.cluster_code?.cluster_code === item)
  //   );
  //   console.log(clusterProductsArray);
  // };

  const fetchStockist = async () => {
    const newClusterCodes: any[] = [];

    Object.values(cartDataObj)?.forEach((item: any) => {
      const clusterCode = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_code;
      if (clusterCode && !clusterCodes.includes(clusterCode) && !newClusterCodes.includes(clusterCode)) {
        newClusterCodes.push(clusterCode);
      }
    });
    console.log("newClusterCodes", newClusterCodes);
    setClusterCodes(newClusterCodes);
    // filterClusterProducts();
    try {
      const res = await getStockist(selectedCounterData?.counter_company_location_id, newClusterCodes);
      if (res?.status !== 200) throw new Error(res?.data?.error);

      setStockist(res?.data?.data);

      const preSelectStockist = res?.data?.data
        ?.filter((item: any) => item?.is_default)
        .map((item: any) => ({
          name: item?.owner_name,
          id: item?.counter_stockist_Lnk_Id,
          company_stockist_code: item?.company_stockist_code,
          cluster_code: item?.cluster_code,
          counter_stockist_Lnk_Id: item?.counter_stockist_Lnk_Id,
          name_id: `${item?.cluster_name} - ${item?.owner_name}`,
          is_default: item?.is_default,
        }));

      setSelectedStockist(preSelectStockist);

      const selectedStockistIds: number[] = [];
      const selectedStockistClusterCodes: number[] = [];
      preSelectStockist.forEach((item: any) => {
        selectedStockistIds.push(item?.counter_stockist_Lnk_Id);
        selectedStockistClusterCodes.push(item?.cluster_code);
      });

      setStockist((prev: any) => {
        return prev.map((item: any) => {
          if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.counter_stockist_Lnk_Id)) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
      });
    } catch (error: any) {
      console.log(error);
      toast({ description: error?.response?.data?.message || "Failed to fetch stockist", variant: "destructive" });
    }
  };

  const handleOrderTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderType(e.target.value);
  };

  useEffect(() => {
    fetchStockist();
  }, [locationCode]);

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <>
            {/* <div className="p-6 px-10 border-b border-purple-200 shadow-md bg-gradient-to-r from-purple-50 to-purple-100"> */}
            <div className="p-6 px-0 border-b shadow-md 2xl:px-20">
              <Button className="text-lg px-10 text-[#7F56D9]" onClick={() => navigate(`/transactions/orders/products/${locationCode}`)}>
                <img src={PurpleLeftArrowIcon} alt="back" className="mr-4" />
                Back to products
              </Button>
              <div className="w-full px-10">
                <h1 className="font-semibold text-[2rem]">Review Order</h1>
              </div>
            </div>
            {placingOrder ? (
              <div className="flex flex-col justify-center items-center h-[50vh]">
                <Spinner />
                <h1 className="text-[1.5rem] mt-4 font-semibold">Please Wait</h1>
                <p className="text-[1rem] text-gray-500">Your order is being placed.</p>
              </div>
            ) : (
              <div className="flex justify-center w-full gap-10 px-10 mt-0 2xl:px-20">
                <div className="mt-10 w-[70%]">
                  <div className="flex items-center justify-between mb-0">
                    <h1 className="font-semibold text-[1.4rem]">All Products</h1>
                    <p className="bg-[#F8F7FF] px-4 py-2 rounded-lg text-[#8051ff] font-medium text-[.9rem]">
                      {Object.keys(cartDataObj)?.length} Items | {totalItems} units
                    </p>
                  </div>

                  {clusterCodes?.map((item: any) => {
                    const clusterProducts = Object.values(cartDataObj)?.filter(
                      (product: any) => product?.companyProducts?.item_division[0]?.cluster_code?.cluster_code === item
                    );
                    const clusterStockist = stockist?.filter((stockist: any) => stockist?.cluster_code === item);
                    const selectedStockists = selectedStockist?.filter((stockist: any) => stockist?.cluster_code === item);
                    return (
                      <OrderReviewProductCard
                        key={item}
                        clusterProducts={clusterProducts}
                        clusterStockist={clusterStockist}
                        selectedStockist={selectedStockists}
                        handleStockistChange={handleStockistChange}
                      />
                    );
                  })}

                  <div className="flex justify-end gap-6 mt-4 mb-20">
                    <p className="text-[1rem] text-gray-500">Order Mode :</p>
                    <div className="flex gap-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="orderType"
                          value="regular"
                          checked={orderType === "regular"}
                          onChange={handleOrderTypeChange}
                          className="text-indigo-500 form-radio"
                        />
                        <span>Regular</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="orderType"
                          value="counterApproval"
                          checked={orderType === "counterApproval"}
                          onChange={handleOrderTypeChange}
                          className="text-indigo-500 form-radio"
                        />
                        <span>Counter Approval</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="w-[25%] mt-10 bg-white">
                  {/* Remark Section */}
                  <div className="mb-6">
                    <label className="block mb-4 text-[1.4rem] font-semibold">Add remarks</label>
                    {
                      <div>
                        <div className="flex flex-col gap-2 mb-10">
                          <h1 className="font-semibold text-gray-600">Customer Upload</h1>
                          <form onSubmit={handleUpload} className="flex justify-between gap-2">
                            <label className="relative px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg cursor-pointer hover:bg-indigo-600">
                              Choose File
                              <input
                                type="file"
                                className="absolute inset-0 opacity-0 cursor-pointer"
                                onChange={handleFileChange}
                                accept="image/*,.pdf,.doc,.docx,.xlsx,.ppt,.pptx,.txt"
                              />
                            </label>
                            <span className="py-2 ml-0 rounded-lg">{file ? file?.name : "No file chosen"}</span>

                            <Button
                              type="submit"
                              disabled={uploading}
                              className="px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-600"
                            >
                              {uploading ? "Uploading..." : "Upload"}
                            </Button>
                          </form>
                        </div>
                        <div className="flex flex-col gap-2 mb-10">
                          <h1 className="font-semibold text-gray-600">Stockist Upload</h1>
                          <form onSubmit={handleUpload} className="flex justify-between gap-2">
                            <label className="relative px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg cursor-pointer hover:bg-indigo-600">
                              Choose File
                              <input
                                type="file"
                                className="absolute inset-0 opacity-0 cursor-pointer"
                                onChange={handleStockistFileChange}
                                accept="image/*,.pdf,.doc,.docx,.xlsx,.ppt,.pptx,.txt"
                              />
                            </label>
                            <span className="py-2 ml-0 rounded-lg">{stockistFile ? stockistFile?.name : "No file chosen"}</span>

                            <Button
                              type="submit"
                              disabled={uploading}
                              className="invisible px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-600"
                            >
                              {uploading ? "Uploading..." : "Upload"}
                            </Button>
                          </form>
                        </div>
                      </div>
                    }
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        placeholder="Type your remark here..."
                        value={remark}
                        onChange={handleRemarkChange}
                        className="flex-grow px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      />
                    </div>
                  </div>

                  {/* Pricing Summary */}
                  <div className="mb-6 space-y-3">
                    <div className="flex justify-between mb-2 text-lg">
                      <span>Subtotal</span>
                      <span className="">₹{(subtotal - getTotalDiscount())?.toFixed(2)}</span>
                    </div>
                    {/* <div className="flex justify-between mb-2">
                      <span className="text-[#616161] font-semibold">Discount</span>
                      <span className="text-[#24a23d] font-semibold">-₹{getTotalDiscount()?.toFixed(2)}</span>
                    </div> */}
                    {/* <div className="flex justify-between mb-2">
                      <span>GST</span>
                      <span>₹22</span>
                    </div> */}
                    <div className="flex justify-between text-lg font-semibold">
                      <span>Total</span>
                      {/* <span>{(subtotal - getTotalDiscount())?.toFixed(2)}</span> */}
                      <span>₹{(subtotal - getTotalDiscount())?.toFixed(2)}</span>
                    </div>
                  </div>

                  {/* Place Order Button */}
                  <Button
                    onClick={handlePlaceOrder}
                    isLoading={isCheckingPendingInvoices}
                    className="w-full py-2 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-600"
                  >
                    {isCheckingPendingInvoices ? "Checking Pending Invoices..." : "Place Order"}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {showPendingInvoices && (
        <div className="w-full max-w-lg mx-auto md:max-w-lg sm:max-w-md xs:max-w-sm">
          <VerificationModal
            isOpen={showPendingInvoices}
            setIsCheckingPendingInvoices={setIsCheckingPendingInvoices}
            onClose={() => {
              // handlePlaceOrder();
              setShowPendingInvoices(false);
            }}
            onConfirm={() => {
              handlePlaceOrder();
              setShowPendingInvoices(false);
            }}
            isCheckingPendingInvoices={isCheckingPendingInvoices}
          />
        </div>
      )}
    </div>
  );
};

interface VerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  setIsCheckingPendingInvoices: Dispatch<SetStateAction<boolean>>;
  isCheckingPendingInvoices: boolean;
}

const VerificationModal = ({ isOpen, onClose, onConfirm, isCheckingPendingInvoices, setIsCheckingPendingInvoices }: VerificationModalProps) => {
  const [pendingInvoicesNumbers, setPendingInvoicesNumbers] = useState("");

  const { toast } = useToast();

  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isOpen) {
      setIsCheckingPendingInvoices(true);
      const arrayTOCheck = ["INDPH-PH", "INDPHPH", "INDPHHS", "INDPH-HS"];
      const isIndph = arrayTOCheck.includes(selectedCounterData?.counter_location?.drph_lnk_type?.dr_ph_lnk_code);
      verifyOrderInvoice({
        User_UCode: idTokenDetails?.["custom:ucode"],
        Counter_UCode: selectedCounterData?.counter_location?.counter_hdr_id?.uid,
        Location_Code: isIndph ? 1 : selectedCounterData?.counter_location?.location_code,
        CompanyCode: selectedCounterData?.company_code,
      })
        .then((res: any) => {
          if (res?.status !== 200) throw new Error(res?.data?.error);
          setPendingInvoicesNumbers(res?.data?.invoicenumbers);
          if (!res?.data?.invoicenumbers?.length) onConfirm();
        })
        .catch((error: any) => {
          console.log(error);
          toast({ description: error?.response?.data?.message || "Failed to fetch pending invoices", variant: "destructive" });
        })
        .finally(() => {
          setIsCheckingPendingInvoices(false);
        });
    }
  }, [isOpen]);

  if (!isOpen || isCheckingPendingInvoices) return null;

  return (
    <Modal isOpen={isOpen} isHeader={false} onCloseModal={onClose}>
      {false ? (
        <Loader />
      ) : (
        <div className="p-8 ">
          <div className="text-center">
            <h2 className="mb-3 text-2xl font-bold text-gray-900">Confirm Order Placement</h2>
            <p className="mb-6 text-gray-600">Please review the following details before confirming your order</p>
          </div>

          <div className="mb-8">
            <div className="flex items-center mb-4">
              <svg className="w-5 h-5 mr-2 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <h3 className="text-lg font-semibold text-gray-800">Pending Invoices</h3>
            </div>

            <div className="p-4 border-l-4 border-yellow-400 rounded-lg bg-yellow-50">
              <div className="flex items-center">
                <span className="text-gray-700">{pendingInvoicesNumbers}</span>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <Button
              onClick={onClose}
              className="min-w-[120px] px-6 py-2.5 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 transition-colors duration-200"
            >
              No
            </Button>
            <Button
              onClick={onConfirm}
              className="min-w-[120px] px-6 py-2.5 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors duration-200 shadow-sm"
            >
              Yes
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default OrderReviewScreen;
